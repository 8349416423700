/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const React = require("react");
const { AppContextProvider } = require("src/layouts/AppState");
const PropTypes = require("prop-types");

exports.onClientEntry = async () => {
  if (typeof window.IntersectionObserver === "undefined") {
    await import("intersection-observer");
  }
};

// Ensure the Tag Commander event object exists
if (!window.tC) {
  window.tC = {};
}
if (!window.tC.event) {
  window.tC.event = {};
}

let navigationHistory = [];

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state && location.state.navigationHistory) {
    navigationHistory = location.state.navigationHistory;
  } else if (!location || !location.state || !location.state.isFromFlavor) {
    navigationHistory.push(location.pathname);
  }

  location.state = location.state || {};
  location.state.navigationHistory = navigationHistory;
};

exports.wrapRootElement = ({ element }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <AppContextProvider>{element}</AppContextProvider>;
};

exports.wrapRootElement.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.state && location.state.keepScrollPosition) {
    return false;
  }
  return true;
};

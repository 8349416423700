const React = require("react");
const PropTypes = require("prop-types");
const { createContext, useState } = React;

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [isProducstLayoutOverview, setIsProducstLayoutOverview] = useState({});
  const [productsScrollPosition, setProductsScrollPosition] = useState(0);

  const setIsProductsLayoutOverviewAndScrollTop = (value) => {
    setIsProducstLayoutOverview(value);
    window.scrollTo(0, 0);
  };

  return (
    <AppContext.Provider
      value={{
        isProducstLayoutOverview,
        setIsProducstLayoutOverview: setIsProductsLayoutOverviewAndScrollTop,
        productsScrollPosition,
        setProductsScrollPosition,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

module.exports = {
  AppContext,
  AppContextProvider,
};
